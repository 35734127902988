<template>
  <div id="user-profile">
    <section
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed w-100"
    >
      <navbar-hasil />
    </section>
    <b-row class="mb-3 justify-content-center">
      <b-col sm="10" md="8">
        <br />
        <br />
        <center>
          <b-card>
            <b>Skor Saya </b>
            <b-badge
              variant="light-danger"
              class="ml-1"
              v-if="hasilUjian.is_passed == false"
              >Tidak Lolos</b-badge
            >
            <b-badge variant="light-success" class="ml-1" v-else>Lolos</b-badge>
            <h1 class="text-primary mt-2 mb-1">
              <b> {{ hasilUjian.my_score }} </b>
            </h1>
            <b-card-body
              class="bg-primary"
              v-if="
                hasilUjian.is_last_subtest == true &&
                hasilUjian.rasionalisasi != null &&
                hasilUjian.rasionalisasi.ranking
              "
            >
              <b-row>
                <b-col
                  md="6"
                  class="text-white"
                  v-if="isSingleRankingResult(hasilUjian.rasionalisasi.ranking)"
                >
                  Rank Saya <br />
                  <span>
                    <b>
                      {{ hasilUjian.rasionalisasi?.ranking?.ke }}
                    </b>
                    /{{ hasilUjian.rasionalisasi?.ranking?.dari }}
                  </span>
                </b-col>
                <b-col md="6">
                  <b-button variant="outline-light" @click="lihatRank"
                    >Lihat Rank Lengkap</b-button
                  >
                </b-col>
              </b-row>
            </b-card-body>
            <h6 v-else>
              Passing grade : <b> {{ hasilUjian.passing_grade }}</b>
            </h6>
          </b-card>

          <b-card
            v-if="
              hasilUjian.is_last_subtest == true &&
              hasilUjian.rasionalisasi != null
            "
          >
            <!-- UTBK -->
            <div class="" v-if="paketDetail.rasionalisasi_type == 'utbk'">
              <div class="" v-if="hasilUjian.rasionalisasi != null">
                <b-card>
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-3"
                      v-for="(item, i) in hasilUjian.hasil_rasionalisasi"
                      :key="i"
                    >
                      <table class="table table-bordered">
                        <tr>
                          <th
                            colspan="2"
                            class="text-center bg-primary text-white"
                          >
                            Pilihan {{ item.pilihan_ke }}
                          </th>
                        </tr>
                        <tr>
                          <td>Nama</td>
                          <td>{{ item.university_info.name }}</td>
                        </tr>
                        <tr>
                          <td>Target Jurusan</td>
                          <td>{{ item.university_info.major_name }}</td>
                        </tr>
                        <tr>
                          <td>Nilai Ambang Batas</td>
                          <td>{{ item.nilai_ambang_batas }}</td>
                        </tr>
                        <tr>
                          <td>Total Skor</td>
                          <td>{{ item.score_total }}</td>
                        </tr>
                        <tr>
                          <td>Peluang Lulus</td>
                          <td>
                            <b-badge
                              variant="light-danger"
                              class="ml-1"
                              v-if="item.peluang_lulus == false"
                              >Tidak Lolos</b-badge
                            >
                            <b-badge variant="light-success" class="ml-1" v-else
                              >Lolos</b-badge
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>Peluang Masuk</td>
                          <td>
                            <b-badge variant="light-secondary" class="ml-1">{{
                              item.evaluasi.status_peluang_masuk
                            }}</b-badge>
                          </td>
                        </tr>
                        <tr>
                          <td>Pesan</td>
                          <td>
                            <p>{{ item.evaluasi.message }}</p>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </div>

            <!-- Mandiri -->
            <div class="" v-if="paketDetail.rasionalisasi_type == 'mandiri'">
              <div class="" v-if="hasilUjian.rasionalisasi != null">
                <b-row>
                  <b-col
                    md="6"
                    class=""
                    v-for="(item, i) in hasilUjian.rasionalisasi
                      .selected_majors"
                    :key="i"
                  >
                    <table class="table table-bordered">
                      <tr>
                        <th
                          colspan="2"
                          class="text-center bg-primary text-white"
                        >
                          Pilihan {{ i + 1 }}
                        </th>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>{{ item.school.name }}</td>
                      </tr>
                      <tr>
                        <td>Target Jurusan</td>
                        <td>{{ item.name }}</td>
                      </tr>
                      <tr>
                        <td>Nilai Ambang Batas</td>
                        <td>
                          {{
                            item.passing_grade_percentage
                              ? item.passing_grade_percentage
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Skor</td>
                        <td>{{ item.nilai_akhir }}</td>
                      </tr>
                      <!-- <tr>
                          <td>Peluang Lulus</td>
                          <td><b-badge variant="light-danger" class="ml-1" v-if="item.peluang_lulus == false">Tidak Lolos</b-badge> <b-badge variant="light-success" class="ml-1" v-else>Lolos</b-badge></td>
                        </tr>
                        <tr>
                          <td>Peluang Masuk</td>
                          <td>
                            <b-badge variant="light-secondary" class="ml-1">{{ item.evaluasi.status_peluang_masuk }}</b-badge>
                          </td>
                        </tr>
                        <tr>
                          <td>Pesan</td>
                          <td>
                            <p>{{ item.evaluasi.message }}</p>
                          </td>
                        </tr> -->
                    </table>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- SMA -->
            <b-card v-if="paketDetail.rasionalisasi_type == 'sma'">
              <b-table-simple responsive bordered>
                <b-tr style="background-color: #fef000">
                  <b-th colspan="6" class="text-center">
                    <h1 class="text-dark">
                      BIMBINGAN BELAJAR {{ appName }}
                    </h1></b-th
                  >
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h2 class="text-dark">{{ hasilUjian.title }}</h2></b-th
                  >
                </b-tr>
                <b-tr>
                  <th>Nama</th>
                  <td colspan="5">{{ hasilUjian.name }}</td>
                </b-tr>
                <b-tr>
                  <th>Sekolah</th>
                  <td colspan="5">
                    {{
                      hasilUjian.school_origin
                        ? hasilUjian.school_origin.nama_sekolah
                        : "-"
                    }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                  >
                </b-tr>
                <b-tr>
                  <b-th
                    rowspan="2"
                    class="text-center"
                    style="background-color: #bebfc3"
                    ><h3 class="text-primary"><b> TRY OUT</b></h3></b-th
                  >
                  <b-th
                    colspan="5"
                    class="text-center text-dark"
                    style="background-color: #a8a7a9"
                  >
                    <b> NILAI PER MAPEL SATUAN </b>
                  </b-th>
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th class="text-primary">BENAR</b-th>
                  <b-th class="text-primary">SALAH</b-th>
                  <b-th>Nilai TO</b-th>
                  <b-th>*KK</b-th>
                  <b-th>Keterangan</b-th>
                </b-tr>
                <b-tr
                  v-for="(item, i) in hasilUjian.rasionalisasi?.nilai_per_mapel"
                  :key="i"
                >
                  <th>{{ item.ujian_name }}</th>
                  <td>{{ item.jumlah_benar }}</td>
                  <td>{{ item.jumlah_salah }}</td>
                  <td>{{ item.nilai }}</td>
                  <td>{{ item.grade ? item.grade : "-" }}</td>
                  <td>{{ item.keterangan ? item.keterangan : "-" }}</td>
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6"></b-th>
                </b-tr>
                <b-tr style="background-color: #fef000">
                  <th>JUMLAH NILAI</th>
                  <td colspan="5">
                    {{ hasilUjian?.rasionalisasi?.jumlah_nilai }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #fef000">
                  <th>RATA-RATA NILAI</th>
                  <td colspan="5">
                    {{ hasilUjian?.rasionalisasi?.nilai_rata }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #fef000">
                  <th>*KK RATA-RATA NILAI</th>
                  <td colspan="5">
                    {{ hasilUjian?.rasionalisasi?.nilai_rata_grade }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #fef000">
                  <th>RANGKING</th>
                  <td colspan="5">
                    <b>
                      {{ hasilUjian.rasionalisasi?.ranking?.ke }}
                    </b>
                    /{{ hasilUjian.rasionalisasi?.ranking?.dari }}
                  </td>
                </b-tr>
              </b-table-simple>
            </b-card>

            <!-- Kedinasan -->
            <b-card v-if="paketDetail.rasionalisasi_type == 'kedinasan'">
              <b-table-simple responsive bordered>
                <b-tr style="background-color: #fef000">
                  <b-th colspan="6" class="text-center">
                    <h1 class="text-dark">
                      BIMBINGAN BELAJAR {{ appName }}
                    </h1></b-th
                  >
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h2 class="text-dark">Kedinasan</h2></b-th
                  >
                </b-tr>
                <b-tr>
                  <th>Nama</th>
                  <td colspan="5">{{ hasilUjian.rasionalisasi.user_name }}</td>
                </b-tr>
                <b-tr>
                  <th>Sekolah Pilihan</th>
                  <td colspan="5">
                    {{ hasilUjian.rasionalisasi.selected_school?.school?.name }}
                  </td>
                </b-tr>
                <b-tr>
                  <th>Asal Sekolah</th>
                  <td colspan="5">
                    {{
                      hasilUjian.rasionalisasi.school_origin
                        ? hasilUjian.rasionalisasi.school_origin.nama_sekolah
                        : "-"
                    }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                  >
                </b-tr>
                <b-tbody
                  v-for="(item, i) in hasilUjian.rasionalisasi.subcats"
                  :key="i"
                >
                  <b-tr>
                    <b-th
                      colspan="6"
                      class="text-dark"
                      style="background-color: #a8a7a9"
                    >
                      <h4>
                        <b> {{ item.name }} </b>
                      </h4>
                    </b-th>
                  </b-tr>
                  <b-tr
                    v-if="item.items"
                    v-for="(data, index) in item.items"
                    :key="index"
                  >
                    <b-th>{{ data.ujian_name }}</b-th>
                    <b-th>{{ data.nilai }}</b-th>
                    <b-th>{{
                      data.is_passed == true ? "Lulus" : "Tidak Lulus"
                    }}</b-th>
                  </b-tr>
                  <b-tr style="background-color: #c5d9f1">
                    <b-th class="text-danger">SKOR</b-th>
                    <b-th class="text-danger">{{
                      item.is_using_percentage == true
                        ? item.sum_percentage_nilai
                        : item.total_nilai
                    }}</b-th>
                    <b-th class="text-danger">{{
                      item.is_passed == true
                        ? "Lulus"
                        : "Tidak Lanjut Ke Tahap Selanjutnya"
                    }}</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>

            <!-- Bintara -->
            <b-card v-if="paketDetail.rasionalisasi_type == 'bintara'">
              <b-table-simple responsive bordered>
                <b-tr style="background-color: #fef000">
                  <b-th colspan="6" class="text-center">
                    <h1 class="text-dark">
                      BIMBINGAN BELAJAR {{ appName }}
                    </h1></b-th
                  >
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h2 class="text-dark">Bintara</h2></b-th
                  >
                </b-tr>
                <b-tr>
                  <th>Nama</th>
                  <td colspan="5">{{ hasilUjian.rasionalisasi.user_name }}</td>
                </b-tr>
                <b-tr>
                  <th>Asal Sekolah</th>
                  <td colspan="5">
                    {{
                      hasilUjian.rasionalisasi.school_origin
                        ? hasilUjian.rasionalisasi.school_origin.nama_sekolah
                        : "-"
                    }}
                  </td>
                </b-tr>
                <b-tr style="background-color: #bebfc3">
                  <b-th colspan="6" class="text-center">
                    <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                  >
                </b-tr>
                <b-tbody
                  v-for="(item, i) in hasilUjian.rasionalisasi.subcats"
                  :key="i"
                >
                  <b-tr>
                    <b-th
                      colspan="6"
                      class="text-dark"
                      style="background-color: #a8a7a9"
                    >
                      <h4>
                        <b> {{ item.name }} </b>
                      </h4>
                    </b-th>
                  </b-tr>
                  <b-tr
                    v-if="item.items"
                    v-for="(data, index) in item.items"
                    :key="index"
                  >
                    <b-th>{{ data.ujian_name }}</b-th>
                    <b-th colspan="2">{{ data.nilai }}</b-th>
                    <!-- <b-th>{{ data.is_passed == true ? "Lulus" : "Tidak Lulus" }}</b-th> -->
                  </b-tr>
                  <b-tr style="background-color: #c5d9f1">
                    <b-th class="text-danger">SKOR</b-th>
                    <b-th class="text-danger">{{ item.total_nilai }}</b-th>
                    <b-th class="text-danger">{{
                      item.is_passed == true
                        ? "Lulus"
                        : "Tidak Lanjut Ke Tahap Selanjutnya"
                    }}</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>

            <!-- Pembahasan -->
            <b-overlay :v-show="loading">
              <div class="">
                <h4 class="mt-4 text-center">
                  <u> Pembahasan </u>
                </h4>

                <b-row class="">
                  <b-col md="12">
                    <div class="table-responsive">
                      <table class="table table-bordered table-responsive">
                        <tr class="bg-primary text-white" variant="primary">
                          <th>No</th>
                          <th>Jenis</th>
                          <th>Subtes</th>
                          <th>Jumlah Soal</th>
                          <th>B</th>
                          <th>S</th>
                          <th>K</th>
                          <th>T</th>
                          <th>Pembahasan</th>
                        </tr>
                        <tr
                          v-if="listRaport.scores[0] != null"
                          v-for="(item, i) in listRaport.scores"
                          :key="i"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.ujian_shortname }}</td>
                          <td>{{ item.ujian_name }}</td>
                          <td>{{ item.jumlah_soal }}</td>
                          <td>{{ item.jumlah_benar }}</td>
                          <td>{{ item.jumlah_salah }}</td>
                          <td>{{ item.jumlah_kosong }}</td>
                          <td>
                            <b-badge variant="success">
                              {{ item.nilai }}
                            </b-badge>
                          </td>
                          <td>
                            <b-button
                              variant="outline-primary"
                              @click="lihatPembahasan(item)"
                              v-if="item.hasil_id != null"
                              >Pembahasan</b-button
                            >
                            <b-button variant="outline-primary" disabled v-else
                              >Pembahasan</b-button
                            >
                          </td>
                        </tr>
                        <tr
                          v-if="listRaport[0].scores[0] != null"
                          v-for="(item, i) in listRaport[0].scores"
                          :key="i"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.ujian_shortname }}</td>
                          <td>{{ item.ujian_name }}</td>
                          <td>{{ item.jumlah_soal }}</td>
                          <td>{{ item.jumlah_benar }}</td>
                          <td>{{ item.jumlah_salah }}</td>
                          <td>{{ item.jumlah_kosong }}</td>
                          <td>
                            <b-badge variant="success">
                              {{ item.nilai }}
                            </b-badge>
                          </td>
                          <td>
                            <b-button
                              variant="outline-primary"
                              @click="lihatPembahasan(item)"
                              v-if="item.hasil_id != null"
                              >Pembahasan</b-button
                            >
                            <b-button variant="outline-primary" disabled v-else
                              >Pembahasan</b-button
                            >
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" class="text-center">Total</td>
                          <td colspan="2" class="text-center">
                            <b-badge variant="danger" class="text-center">
                              {{ sumScores }}
                            </b-badge>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </b-card>
        </center>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

import NavbarHasil from "./components/NavbarHasil.vue";
/* eslint-disable global-require */
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    NavbarHasil,
  },

  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
    hasilUjian() {
      return this.$store.state.ujian.hasilUjian;
    },
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
  },

  data() {
    return {
      listRaport: null,
      loading: false,
      sumScores: 0,
    };
  },

  methods: {
    isSingleRankingResult(ranking) {
      return Array.isArray(ranking) ? false : true;
    },
    lihatRank() {
      this.$router.push({
        name: "ranking-siswa-tryout",
        query: { p: this.paketDetail?.id, j: this.$route.query?.j },
      });
    },
    getHasil() {
      this.loading = true;
      let params = {
        paket_id: this.paketDetail.id,
        jadwal_id: this.tryoutDetail.jadwal_id,
        kategori_paket_id: this.tryoutDetail.category_id,
      };
      this.$store
        .dispatch("raport/list", params)
        .then((res) => {
          this.loading = false;
          this.listRaport = res.data.data;
          if (this.listRaport[0] != null) {
            var sumScores = this.listRaport[0].scores.reduce((total, item) => {
              return (total += parseInt(item.nilai));
            }, 0);
          } else {
            var sumScores = this.listRaport.scores.reduce((total, item) => {
              return (total += parseInt(item.nilai));
            }, 0);
          }

          this.sumScores = sumScores;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
          this.getHasil();
        });
    },

    lihatPembahasan(item) {
      this.$store.commit("ujian/SET_HASIL", item);
      this.$router.push({ name: "pembahasan-soal-tryout" });
    },
  },

  created() {
    if (this.hasilUjian == null) {
      this.$router.go(-1);
    }
    this.getHasil();
  },
};
</script>
